<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>
        <div class="d-flex justify-center pa-5">
          <v-progress-circular
            indeterminate
            size="40"
            width="3"
          />
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'SectionLoadingSpinner'
};
</script>

<style scoped>

</style>
